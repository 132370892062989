import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../components/seo"

const BlogDetail = ({ data }) => {
  const regex = new RegExp("{{URL}}/.{18}", "g")
  return (
    <Layout className="main inner-page-with-banner">
      {/* TODO: Confirm change to data.blog.seoTitle works */}
      {/* TODO: Confirm change to data.blog.seoDescription works */}
      <SEO
        title={data.blog.seoTitle || `Walpole Outdoors`} 
        description={data.blog.seoDescription || `The standard all other fence and outdoor structures are measured by. `} 
      />
      <InnerWrapper>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/blog">Blog</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/blogs/${data.blog.seoURL}`}>{data.blog.title}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col md={12}>
            <article className="blog-featured">
              <div className="post-thumbnail">
                {data.blog.imagePaths.length > 0 ? (
                  <img src={data.blog.imagePaths[0]} />
                ) : (
                  <img src="" />
                )}
              </div>
              <h1 className="generalheader blogtitle">{data.blog.title}</h1>
              <hr className="bloglisthr" />
              <div className="entry-meta">
                <span className="posted-on">Posted on </span>
                {data.blog.postedDate} | Category:
              </div>
              <div className="blogcontent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.blog.content
                      ? data.blog.content.replace(regex, (match) => {
                          let imageUrl = data.blog.imagePaths.filter((path) =>
                            path.includes(match.slice(-18))
                          )
                          return `<img src="${imageUrl}" />`
                        })
                      : null,
                  }}
                />
              </div>
            </article>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default BlogDetail

export const query = graphql`
  query($id: String!) {
    blog(id: { eq: $id }) {
      title
      content
      category
      seoURL
      seoTitle
      seoDescription
      postedDate
      imagePaths
    }
  }
`
